import React from 'react'
import Layout from '../../components/layout/Layout'
import FashionTextileProjectsSection from '../../sections/projects/textile/FashionTextileProjectsSection'
import HomeTextileProjectsSection from '../../sections/projects/textile/HomeTextileProjectsSection'

const Textile = () => {
  return (
    <Layout pageName="textile">
      <FashionTextileProjectsSection />
      <HomeTextileProjectsSection />
    </Layout>
  )
}

export default Textile
