import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../../components/common/wrappers/Wrappers'
import ProjectsCardsSectionComponent from '../../../components/sections/ProjectsCardsSectionComponent'

const HomeTextileProjectsSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allHomeTextileProjectsCardsJson {
        edges {
          node {
            slug
            path
            title
            subTitle
            info01
            info02
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 750
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [768, 1380]
                )
              }
            }
            detail {
              childImageSharp {
                gatsbyImageData(
                  width: 60
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [768, 1380]
                )
              }
            }
          }
        }
      }
    }
  `)
  const cardData = data.allHomeTextileProjectsCardsJson.edges
  return (
    <SectionWrapper>
      <ProjectsCardsSectionComponent
      sectionTitle={intl.formatMessage({ id: 'textile.home-textile.title' })}
      sectionSubTitle={intl.formatMessage({ id: 'textile.home-textile.subTitle' })}
      cardData={cardData}
      />
    </SectionWrapper>
  )
}

export default injectIntl(HomeTextileProjectsSection)


